/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require('../scss/page.scss');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('cookieconsent/build/cookieconsent.min.css')

import 'bootstrap';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'select2';
import 'slick-carousel';
import Cookies from 'js-cookie';
window.Cookies = Cookies;
import scrollAnchor from './utils/scroll';
require('@fancyapps/fancybox');
import 'cookieconsent';

$(document).on('click', 'a', scrollAnchor);

$(function () {
  $('.m-menu .m-toggle').click(function () {
    $(this).toggleClass('active');
    $(this).parent().toggleClass('opened');
    $('body').toggleClass('noscroll');
  });

  $('.header_menuholder').click(function () {
    const h = $('header.header_main').toggleClass('active');

    $(window).scroll();
  });

  $('aside.nav .nav_main ul li a').click(function () {
    $('header.header_main').removeClass('active');
  })

  $('.select2').select2({
    minimumResultsForSearch: -1
  });

  $('.carousel-holder').slick({
    infinite: !$('#smweb-panel').length,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: $('span.car-arrow-next'),
    prevArrow: $('span.car-arrow-prev'),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  if (!Cookies.get('seen_popup')) {
    $('#coronaModal').modal('show').on('hidden.bs.modal', function () {
      Cookies.set('seen_popup', true, { expires: 365 });
    });
  }

  if (!Cookies.get('seen_popup_discount')) {
    $('#discountModal').modal('show').on('hidden.bs.modal', function () {
      Cookies.set('seen_popup_discount', true, { expires: 365 });
    });
  }

  $('.more-info').click(function () {
    Cookies.set('seen_popup', true, { expires: 365 });
  });

  $(window).scroll(function () {
    const h = $('header');
    if ((window.scrollY < 10) && (!h.hasClass('active'))) {
      h.removeClass('fixed');
    } else {
      h.addClass('fixed');
    }
  });

  cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#000"
      },
      "button": {
        "background": "#E52321"
      }
    },
    "theme": "edgeless",
    "content": {
      "message": "Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus.",
      "dismiss": "OK, einverstanden",
      "link": "Erfahren Sie mehr",
      "href": "/build/documents/impressum.pdf"
    }
  });
});


