import $ from 'jquery';

export default scrollAnchor;

export function scrollAnchor () {
  let url = window.location.href.split('#')[0];

  let href = $(this).attr('href');
  if (typeof href === 'undefined' || !href.match('^(' + url + ')?\#.+$')) {
    return true;
  }

  let id = href.replace(url, '').replace('/', '');
  if ($(id).length) {
    scrollTo(id, 500, 0);
    window.history.pushState(null, id, url+id);
  }

  return false;
}

export function scrollTo (id, speed, mOffset) {
  //+5 je kvuli firefoxu, kterej neumi jezdit spravne na kotvicky
  $('html,body').stop().animate({ scrollTop: $(id).offset().top - mOffset + 5 }, speed, function () {});
}
